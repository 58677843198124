import { useMemo } from 'react';

import { stringToAnchorId } from 'helpers/stringToAnchorId';
import { RichText } from 'components/RichText';
import { isRichText } from 'helpers/isRichText';
import { StyledHtml } from 'components/StyledHtml';
import { Detail } from './Detail.styled';
import { Question } from './Question.styled';
import { Answer } from './Answer.styled';
import { AnswerText } from './AnswerText.styled';
import { ANSWER_ANCHOR_PREFIX, QUESTION_ANCHOR_PREFIX } from './constants';

import type { AccordionItemProps } from './types';
import type { FC } from 'react';

export const AccordionItem: FC<AccordionItemProps> = ({
  forceOpenQuestionHash,
  detail,
  injectFAQStructuredData,
  background,
}) => {
  const withStructuredData = injectFAQStructuredData && !detail.excludeInStructuredData;

  const questionAsAnchor = stringToAnchorId(detail.question);
  const questionAnchor = `${QUESTION_ANCHOR_PREFIX}-${questionAsAnchor}`;
  const answerAnchor = `${ANSWER_ANCHOR_PREFIX}-${questionAsAnchor}`;

  const content = useMemo(() => {
    if (isRichText(detail.answer)) return <RichText richText={detail.answer} />;
    if (typeof detail.answer === 'string') return <StyledHtml html={detail.answer} />;

    return detail.answer;
  }, [detail.answer]);

  return (
    <Detail
      key={detail.question}
      withStructuredData={withStructuredData}
      id={questionAnchor}
      open={forceOpenQuestionHash === questionAnchor}
    >
      <Question withStructuredData={withStructuredData} aria-controls={answerAnchor}>
        {detail.question}
      </Question>

      <Answer withStructuredData={withStructuredData} id={answerAnchor} background={background}>
        <AnswerText withStructuredData={withStructuredData}>{content}</AnswerText>

        {detail.readMore && (
          <>
            <br />
            {detail.readMore}
          </>
        )}
      </Answer>
    </Detail>
  );
};
