import styled from 'styled-components';

import type { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  withStructuredData: boolean;
}

export const AnswerText = styled.div.attrs((props: Props) => {
  // WARNING: These should be capitalized, eg. `itemProp`, so they are passed down to the DOM.
  if (props.withStructuredData) return { itemProp: 'text' };

  return undefined;
})<Props>`
  & > *:last-of-type {
    margin-bottom: 0;
  }
`;
