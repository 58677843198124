import styled from 'styled-components';

import { colorFromPropsMixin } from 'styled/helpers/colors';

import { getDimension } from 'styled/helpers/getDimension';
import type { HTMLAttributes } from 'react';
import type { Color } from 'styled/types';

interface Props extends HTMLAttributes<HTMLDivElement> {
  withStructuredData: boolean;
  background?: Color;
  color?: Color;
}

export const Answer = styled.div.attrs((props: Props) => {
  if (props.withStructuredData) {
    // WARNING: These should be capitalized, eg. `itemProp`, so they are passed down to the DOM.
    return {
      itemScope: true,
      itemProp: 'acceptedAnswer',
      itemType: 'https://schema.org/Answer',
    };
  }

  return undefined;
})<Props>`
  padding: ${getDimension('size4')};
  padding-left: ${getDimension('size1')};
  ${colorFromPropsMixin}
`;
