import { useEffect, useMemo, useState } from 'react';

import { Container } from 'components/Container';

import { stringToAnchorId } from 'helpers/stringToAnchorId';

import { AccordionItem } from './AccordionItem';

import { QUESTION_ANCHOR_PREFIX } from './constants';
import type { FC } from 'react';
import type { AccordionProps } from './types';

export const Accordion: FC<AccordionProps> = ({
  details,
  title,
  injectFAQStructuredData = false,
  background,
  note,
  padding,
}) => {
  /**
   * This is the question we should force open from when a user clicks externally. \
   * NOTE: This may not work when clicking on the same page; @see https://github.com/brohlson/gatsby-plugin-anchor-links/issues/38
   *
   * We store this in state as there may be other links a user may click, eg. a nested anchor link…
   */
  const [forceOpenQuestionHash, setForceOpenQuestionHash] = useState<string>();
  const currentHash: string | undefined =
    typeof window === 'undefined' ? undefined : window?.location?.hash;

  useEffect(() => {
    // We only update the forceOpenQuestionHash if the hash starts with `question-`…
    if (typeof currentHash !== 'string') return;

    const afterHash = window.location.hash.substring(1); // remove `#` from `#question-`
    if (afterHash.startsWith(`${QUESTION_ANCHOR_PREFIX}-`)) setForceOpenQuestionHash(afterHash);
  }, [currentHash]);

  const accordionItems = useMemo(
    () =>
      details.map(detail => (
        <AccordionItem
          key={detail.question}
          forceOpenQuestionHash={forceOpenQuestionHash}
          injectFAQStructuredData={injectFAQStructuredData}
          background={background}
          detail={detail}
        />
      )),
    [background, injectFAQStructuredData, forceOpenQuestionHash, details]
  );

  return (
    <Container
      manageLayout={false}
      background={background}
      id={stringToAnchorId(title)}
      padding={padding}
    >
      <h2>{title}</h2>

      {accordionItems}

      {note && (
        <>
          <br /> {note}
        </>
      )}
    </Container>
  );
};
